<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center" v-if="!showInviteForm">
        <h3 class="mb-0 mr-1">Convites</h3>
        <b-button variant="primary" @click="$router.push({ name: 'admin.investor.invites.new' })">
          <span class="mb-0 text-dark font-weight-bolder"> Criar convite </span>
        </b-button>
      </div>

      <invite-form v-else @closeForm="closeInviteForm" />

      <invite-card
        v-for="invite in invites"
        :key="invite.id"
        class="mt-1"
        :invite="invite"
        @inviteDeleted="fetchInvites"
        @inviteResent="fetchInvites"
      />
      <div class="d-flex justify-content-end align-items-center mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mr-2"
          :disabled="!activePagination"
          @change="changePage"
          ref="paginationInvites"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>

        <div class="d-flex flex-row justify-content-end align-items-center">
          <label class="mb-0 mr-1">Mostrar</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" />
          <label class="mb-0 mr-1 ml-1">registros</label>
        </div>
        <span v-show="invites.length" class="mb-0 h5"> {{ invites.length }} </span>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton, BInputGroup, BInputGroupAppend, BFormInput, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import InviteForm from '@/views/admin/investor/components/InviteForm.vue'
import InviteCard from '@/views/admin/investor/components/InviteCard.vue'

export default {
  name: 'AdminInvestorIndexPage',
  components: {
    vSelect,
    BCard,
    BButton,
    Datatable,
    BFormInput,
    InviteForm,
    InviteCard,
    BInputGroup,
    BInputGroupAppend,
    BPagination
  },
  data() {
    return {
      currentPage: 1,
      activePagination: false,
      perPage: 10,
      totalRows: 0,
      perPageOptions: [10, 25, 50],
      invites: [],
      search: 'ddsd',
      showInviteForm: false,
    }
  },
  async mounted() {
    await this.fetchInvites()
    if (this.invites.length === 0){
      this.showInviteForm = true
    }else{
      this.activePagination = true
    }
  },
  methods: {
    async fetchInvites() {
      const { data } = await this.$store.dispatch('admin/fetchInvestorInvites', {
        search: this.search,
        per_page: this.perPage,
        page: this.currentPage
      })
      this.invites = data.data
      this.totalRows = data.total
    },
    closeInviteForm() {
      this.showInviteForm = false
      this.fetchInvites()
    },
    async changePage(){
      this.activePagination = false
      await this.$refs.paginationInvites
      await this.fetchInvites()
      this.activePagination = true
    },
    async changePageItemsQuantity(){
      this.currentPage = 1
      await this.changePage()
    }
  },
  watch: {
    perPage: function (newVal) {
      this.changePageItemsQuantity();
    },
  }
}
</script>

<style lang="scss">
.mw-173 {
  max-width: 173px;
}
</style>
